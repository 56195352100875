<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import giftaiditLogo from '@/assets/images/giftaidit.svg'
import Header from '@/components/views/manage-account/Header.vue'
import DeclarationGrid from '@/components/views/manage-account/DeclarationGrid.vue'
import { LANDING_PAGE } from '@/types'

const store = useStore()
const router = useRouter()

const props = defineProps({
  renew: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const btnLoading = ref(false)
const termsAgreed = ref(false)
const termsLink = import.meta.env.VITE_LEGAL_TERMS_LINK
const privacyLink = import.meta.env.VITE_LEGAL_PRIVACY_LINK

const errorMargin = computed(() => ({
  'error-agreement-margin': store.getters.globalError,
}))

const header = computed(() =>
  props.renew
    ? 'Dashboard.Settings.RenewAuth.Agreement'
    : 'LoginRegister.Declaration.Header'
)

const postAuth = () => {
  btnLoading.value = true

  setTimeout(() => {
    store.commit('SET_LOADING', true)
  }, 500)

  setTimeout(async () => {
    try {
      await store.dispatch('postAuth')

      if (!props.renew) {
        store.commit('SET_LANDING_PAGE', LANDING_PAGE.DASHBOARD)
        router.push({ name: 'DONOR_FLOW_ManageAccountUpdate' })
      } else {
        router.push({ name: 'Settings' })
      }
    } catch (error) {
      if (!props.renew) {
        router.push({ name: 'Registration' })
      } else {
        router.push({ name: 'Settings' })
      }
    }
  }, 1000)
}

const setTermsModalVisibility = (visibility: boolean) => {
  store.commit('SET_TERMS_MODAL_VISIBILITY', visibility)
}

const setPrivacyModalVisibility = (visibility: boolean) => {
  store.commit('SET_PRIVACY_MODAL_VISIBILITY', visibility)
}

onMounted(() => {
  store.commit('SET_LOADING', false)
})
</script>

<template>
  <div
    class="m-auto w-fixed"
    :class="errorMargin"
    data-testid="declaration-section"
  >
    <Header
      :img-src="giftaiditLogo"
      :img-alt="$t('LoginRegister.Index.ImageAlt')"
      img-width="160px"
      :header="$t(header)"
    />

    <DeclarationGrid
      v-for="(statement, idx) in $tm(
        'LoginRegister.Declaration.DeclarationStatements'
      )"
      :key="idx"
    >
      <template #side>
        {{ $rt(statement['Icon']) }}
      </template>
      <template #title>
        {{ $rt(statement['Title']) }}
      </template>
      <template #text>
        <p>{{ $rt(statement['Text']) }}</p>
      </template>
    </DeclarationGrid>

    <DeclarationGrid class="my-4">
      <template #side>
        <div class="d-flex justify-content-center">
          <input
            id="terms"
            v-model="termsAgreed"
            type="checkbox"
            class="custom-checkbox-input"
          />
        </div>
      </template>
      <template #text>
        <i18n-t
          keypath="LoginRegister.Declaration.Terms"
          tag="label"
          for="terms"
          class="form-check-label"
          scope="global"
        >
          <template #terms>
            <a
              :href="termsLink"
              class="fw-bold text-decoration-none"
              data-testid="legal-terms-link"
              @click.prevent="setTermsModalVisibility(true)"
            >
              {{ $t('terms') }}
            </a>
          </template>
          <template #privacy>
            <a
              :href="privacyLink"
              class="fw-bold text-decoration-none"
              data-testid="legal-privacy-link"
              @click.prevent="setPrivacyModalVisibility(true)"
            >
              {{ $t('privacy') }}
            </a>
          </template>
        </i18n-t>
      </template>
    </DeclarationGrid>

    <div class="mb-5 col">
      <button
        id="agree"
        :aria-label="$t('LoginRegister.Declaration.ConfirmButton')"
        class="sa-btn sa-btn-primary-solid sa-btn-sm mx-auto px-4"
        :disabled="!termsAgreed || btnLoading"
        @click.prevent="postAuth"
      >
        <div
          v-if="btnLoading"
          class="spinner btnSpinner"
          role="alert"
          :aria-label="$t('Accessibility.Button.Loading')"
        ></div>
        <span v-else>{{ $t('Buttons.Agree') }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:root {
  --form-control-color: var(--swiftaid-mid-blue);
}

.w-fixed {
  @media (min-width: 768px) {
    max-width: 650px;
  }
}

.custom-checkbox-input {
  appearance: none;
  background-color: var(--form-background);
  margin: 4px 0 0;
  font: inherit;
  color: var(--swiftaid-mid-blue);
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid var(--swiftaid-mid-blue);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  overflow: hidden;
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    opacity: 0;
    color: var(--swiftaid-mid-blue);
    transform-origin: bottom left;
    background-color: var(--swiftaid-mid-blue);
  }

  &:checked {
    background-color: var(--swiftaid-mid-blue);
  }

  &:checked::before {
    opacity: 1;
    color: var(--white);
    background-color: var(--white);
  }
}
</style>
