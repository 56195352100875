<script setup lang="ts">
import { onMounted, ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import type * as Bootstrap from 'bootstrap'

const store = useStore()

const bootstrap: any = inject('bootstrap') as typeof Bootstrap | undefined
const modal = ref<Bootstrap.Modal | null>(null)

const legalDocModalContent = ref<HTMLElement | null>(null)
const loadingMsg = ref('Loading...')
const loadingError = ref(false)
const modalContent = computed(() => {
  if (store.state.termsModalIsVisible) {
    return {
      title: 'LoginRegister.Footer.TermsModal.Header',
      mutation: 'SET_TERMS_MODAL_VISIBILITY',
      link: import.meta.env.VITE_LEGAL_TERMS_LINK,
      backupLink: import.meta.env.VITE_LEGAL_TERMS_LINK_BACKUP,
    }
  }

  return {
    title: 'LoginRegister.Footer.PrivacyModal.Header',
    mutation: 'SET_PRIVACY_MODAL_VISIBILITY',
    link: import.meta.env.VITE_LEGAL_PRIVACY_LINK,
    backupLink: import.meta.env.VITE_LEGAL_PRIVACY_LINK_BACKUP,
  }
})

const fetchAndDisplayLegalContent = async () => {
  const loadingTimeout = setTimeout(() => {
    loadingError.value = true
    loadingMsg.value = 'Loading the document is taking longer than expected.'
    console.error('Excessive delay loading legal document')
  }, 4000)

  try {
    const response = await fetch(modalContent.value.link)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const htmlContent = await response.text()

    const parser = new DOMParser()
    const parsedDoc = parser.parseFromString(htmlContent, 'text/html')
    const targetContent = parsedDoc.querySelector(
      '#swiftaid-legal-doc-target-content'
    )

    if (!targetContent) {
      throw new Error('Could not find legal content in document')
    }

    legalDocModalContent.value!.innerHTML = targetContent.innerHTML
  } catch (error) {
    loadingError.value = true
    loadingMsg.value = 'An error occurred loading the document.'
    console.error('Failed to load legal document:', error)
  } finally {
    clearTimeout(loadingTimeout)
  }
}

onMounted(() => {
  const modalEle = document.getElementById('modal')
  modalEle?.addEventListener('hidden.bs.modal', () => {
    store.commit(modalContent.value.mutation, false)
  })

  if (bootstrap && bootstrap.Modal) {
    modal.value = new bootstrap.Modal('#modal') as Bootstrap.Modal
    modal.value?.show()
  } else {
    console.error('Bootstrap or Modal not available')
  }

  fetchAndDisplayLegalContent()
})
</script>

<template>
  <div
    id="modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="legalDocModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="legalDocModalLabel" class="modal-title">
            {{ $t(modalContent.title) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          ref="legalDocModalContent"
          id="legal-doc-modal-content"
          class="swiftaid-legal-doc modal-body text-left p-4"
        >
          {{ loadingMsg }}
          <span v-if="loadingError" class="msg">
            <a :href="modalContent.backupLink" target="_blank">
              View in a new window
            </a>
          </span>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="sa-btn sa-btn-primary-solid sa-btn-sm"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.msg {
  margin-top: 0.5rem;
  opacity: 0.75;
}

#legal-doc-modal-content {
  --link-visited: purple;
  --panel-color: rgb(198, 228, 255);
  --panel-color-light: rgb(198, 228, 255, 0.4);
  --padding: 0.75rem;
  --neg-padding: calc(var(--padding) * -1);
  --dd-padding: 1rem;

  a {
    text-decoration: none;

    &:visited {
      color: var(--link-visited);
    }
  }

  dl {
    border-radius: 5px;
    overflow: hidden;
    background-color: var(--panel-color-light);
    padding: var(--padding);
    margin-bottom: 3rem;
  }

  dt {
    background-color: var(--panel-color-light);
    padding: var(--padding);
    margin-left: var(--neg-padding);
    margin-top: var(--neg-padding);
    margin-right: var(--neg-padding);
    margin-bottom: var(--padding);
    border-bottom: 1px solid var(--panel-color);
  }

  dd {
    padding-left: var(--dd-padding);
    padding-right: var(--dd-padding);
    display: list-item;
    list-style-type: disc;
    margin-left: calc(var(--dd-padding) * 2);
    padding-left: 0;

    ul {
      margin-bottom: 0;

      li {
        list-style-type: circle;
      }
    }

    p {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  h1 {
    font-size: 2rem;
    margin: 0 0 1rem;
    padding: 0;
  }

  h2 {
    font-size: 1.25rem;
    margin: 3rem 0 1rem;
    padding: 0;
  }

  h3 {
    font-size: 1.2rem;
    margin: 1.5rem 0 0.5rem;
    padding: 0;
  }

  h4 {
    font-size: 1rem;
    margin: 1.5rem 0 0.5rem;
    padding: 0;
  }

  p {
    line-height: 1.55rem;
    padding: 0;
  }
}
</style>
